<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>E-Posta Şeması Düzenleme</CCardHeader>
                    <CCardBody>
                        <CForm>
                            <CInput
                                label="İsim"
                                horizontal
                                autocomplete="name"
                                description="Bu alanı değiştirmeyin!"
                                :value.sync="item.name"
                            />
                            <CInput
                                label="Açıklama"
                                horizontal
                                :value.sync="item.description"
                            />
                            <CInput
                                label="Konu"
                                horizontal
                                :value.sync="item.subject"
                            />
                            <label>E-Posta içeriği</label>
                            <VueEditor
                                v-model="item.content"
                            />
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="save()"
                        >
                            <CIcon name="cil-check-circle"/> Kaydet
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import { getProductCategories } from '../../utils';


export default {
    name: 'MailTemplateDetail',

    components: {
        VueEditor
    },

    data () {
        return {
            item: {
                name: '',
                description: '',
                subject: '',
                content: '' 
            },
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        async save() {
            try {
                const response = await axios({
                    method: 'patch',
                    url: `/mail-templates/${this.$route.params.id}`,
                    data: this.item
                });

                if (response.status === 201) {
                    this.alert = {
                        isOpen: true,
                        color: 'success',
                        message: 'E-Posta şeması başarıyla değiştirildi'
                    };
                }
            } catch (error) {
                console.log(error)
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },
    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: `/mail-templates/${this.$route.params.id}`
        });

        this.item = response.data;
    }
}
</script>

<style lang="scss" scoped>
    label {
        margin-bottom: 15px;
    }
</style>
